// Nav - Active
$("header nav a[which='"+ $(".hero-title").text().trim() +"']").addClass("on");

// Tabs
if ( $(".cat-tab").length ) {
    function tabs() {
        $(".cat-tabs-cont").height( $(".cat-tab.on").innerHeight() );
    }
    $(".cat-button").on("click", function() {
        const cont = $(this).closest(".cat-tabs");
        cont.find(".cat-tab, .cat-button").removeClass("on");
        cont.find(".cat-tab[nth='"+ $(this).attr("nth") +"']").addClass("on");
        $(this).addClass("on");
        tabs();
    });
    $(".cat-buttons").each( function() { // 1 Expected
        $(this).find(".cat-button:first").trigger("click");
    });
    $(window).on("resize", tabs);
    tabs();
}

// Nav Toggler
if ( $(".nav-toggler").length ) {
    $(".nav-toggler").on("click", function() {
        $(this).toggleClass("on");
        $(".nav").toggleClass("on");
        if ( $(".nav").is(".on") ) {
            $(".nav").css({"max-height": $(".nav > nav").innerHeight() });
        } else {
            $(".nav").css({"max-height": 0 });
        }
    });
}

// Thanks Return Button
if ( $(".thanks-cta").length ) {
    $(".thanks-cta").on("click", function() {
        if ( ! $(this).is("[href]") ) {
            if ( localStorage.getItem("prev") == undefined ) {
                location.path = location.hostname;
            } else {
                //location.path = localStorage.getItem("prev");
                history.back();
            }
        }
    });
}

// Set Prev URL
