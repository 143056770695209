// importeer hier alle scripts die je maakt 
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

import "./sector.js";
import "./boxSizing.js";
import "./hamburgerMenu.js";
import "./favicon.js";